import { useSession } from "@core/hooks/useSession";
import { AxiosError } from "axios";
import { isNil } from "lodash/fp";
import useSWR, { SWRResponse } from "swr";
import invariant from "tiny-invariant";

import api from "../api";
import { APIError } from "../types";
import {
  CreateFormSubmissionsResponse,
  FormSubmission,
  MemberFormSubmissions,
} from "./types";

export const getMemberFormSubmissions = (
  userId: number | string | undefined,
  accessToken: string | undefined
): Promise<MemberFormSubmissions> => {
  invariant(userId != null, "userId is required");

  return api
    .get<MemberFormSubmissions>(
      `/v2/clinician/members/${encodeURIComponent(userId)}/forms_viewer`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)

    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });
};

export const useMemberFormSubmissions = (
  userId: string | number | undefined
) => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  const swrProps = useSWR<MemberFormSubmissions, Error>(
    !isNil(userId) && !isNil(accessToken)
      ? ["form-submissions", userId, accessToken]
      : undefined,
    () => getMemberFormSubmissions(userId, accessToken)
  );

  return swrProps;
};

const getMemberFormSubmission = (
  userId: number | string | undefined,
  id: number | string,
  accessToken: string | undefined
): Promise<FormSubmission> => {
  invariant(userId != null, "userId is required");

  return api
    .get<FormSubmission>(
      `/v2/clinician/members/${encodeURIComponent(
        userId
      )}/form_submission/${encodeURIComponent(id)}`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });
};

export const useMemberFormSubmission = (
  userId: string | number,
  id: number | string
): SWRResponse<FormSubmission, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  const swrProps = useSWR<FormSubmission, Error>(
    !isNil(userId) && !isNil(id) && !isNil(accessToken)
      ? ["form-submission", userId, id, accessToken]
      : undefined,
    () => getMemberFormSubmission(userId, id, accessToken)
  );

  return swrProps;
};

export const saveMemberFormSubmission = (
  userId: number | string,
  id: number | string,
  payload: Pick<FormSubmission, "form_fields_data" | "is_completed">,
  accessToken: string | undefined
): Promise<FormSubmission> =>
  api
    .post<FormSubmission>(
      `/v2/clinician/members/${encodeURIComponent(
        userId
      )}/form_submission/${encodeURIComponent(id)}`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)

    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

interface CreateFormSubmissionsPayload {
  form_names: string[];
  appointment_id: number | null;
  due_at: string;
}

export const createFormSubmissions = (
  userId: number | string,
  payload: CreateFormSubmissionsPayload,
  accessToken: string | undefined
): Promise<CreateFormSubmissionsResponse> =>
  api
    .post<CreateFormSubmissionsResponse>(
      `/v2/clinician/members/${encodeURIComponent(
        userId
      )}/bulk_create_form_submissions`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const deleteFormSubmission = (
  userId: number | string,
  id: number | string,
  accessToken: string | undefined
): Promise<FormSubmission> =>
  api
    .post<FormSubmission>(
      `/v2/clinician/members/${encodeURIComponent(
        userId
      )}}/form_submission/${encodeURIComponent(id)}/delete`,
      undefined,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const getFormNamesString = (arr: string[]): string => {
  let str = "";

  arr.forEach((formName) => {
    if (str !== "") {
      str += ", ";
    }

    if (formName === "dass_21") {
      str += "DASS-21";
    } else if (formName === "whodas2") {
      str += "WHODAS";
    } else if (formName === "pswq_c") {
      str += "PSWQ-C";
    } else if (formName === "lsas_ca") {
      str += "LSAS-CA";
    } else if (formName === "smsada") {
      str += "SMSAD";
    } else if (formName === "smaa") {
      str += "SMA";
    } else if (formName === "smspa") {
      str += "SMSP";
    } else if (formName === "asi_3") {
      str += "ASI-3";
    } else if (formName === "gad_7") {
      str += "GAD-7";
    } else if (formName === "phq_9") {
      str += "PHQ-9";
    } else if (formName === "p_qles_q") {
      str += "P-QLES-Q";
    } else if (formName === "dass_y") {
      str += "DASS-Y";
    } else {
      str += formName.toUpperCase();
    }
  });

  if (str === "") {
    str = "-";
  }

  return str;
};

export const changeFormDueDate = (
  userId: number | string,
  formId: number,
  payload: unknown,
  accessToken: string | undefined
): Promise<unknown> =>
  api
    .post<unknown>(
      `/v2/clinician/members/${encodeURIComponent(
        userId
      )}/form_submission/${encodeURIComponent(formId)}/due_at`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)

    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });
