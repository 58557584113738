import React from "react";

interface SuggestionOptionsProps {
  options: {
    id: string;
    label: string;
    value: string;
    handler: (value: string) => void;
  }[];
}

const SuggestionOptions: React.FC<SuggestionOptionsProps> = ({ options }) => {
  return (
    <div className="react-chatbot-kit-chat-widget-suggestions">
      <h2 className="react-chatbot-kit-chat-widget-suggestions-header">
        Suggested Questions
      </h2>
      <div className="react-chatbot-kit-chat-widget-suggestions-container">
        {options.map((option) => (
          <button
            key={option.id}
            type="button"
            className="react-chatbot-kit-chat-widget-suggestions-item"
            onClick={() => option.handler(option.value)} // Wrap the handler in an anonymous function
          >
            <div>
              <svg
                width="25"
                height="26"
                viewBox="0 0 25 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_4222_9280)">
                  <path
                    d="M22.12 7.37L20.75 8L22.12 8.63L22.75 10L23.38 8.63L24.75 8L23.38 7.37L22.75 6L22.12 7.37ZM19.75 6L20.69 3.94L22.75 3L20.69 2.06L19.75 0L18.81 2.06L16.75 3L18.81 3.94L19.75 6Z"
                    fill="#7080EB"
                  />
                  <path
                    d="M7.25 24H12.25M12.75 17.3264C15.1149 16.2029 16.75 13.7924 16.75 11C16.75 7.13401 13.616 4 9.75 4C5.88401 4 2.75 7.13401 2.75 11C2.75 13.7924 4.38505 16.2029 6.75 17.3264V18C6.75 18.9319 6.75 19.3978 6.90224 19.7654C7.10523 20.2554 7.49458 20.6448 7.9846 20.8478C8.3522 21 8.8181 21 9.75 21C10.6819 21 11.1478 21 11.5154 20.8478C12.0054 20.6448 12.3948 20.2554 12.5978 19.7654C12.75 19.3978 12.75 18.9319 12.75 18V17.3264Z"
                    stroke="url(#paint0_linear_4222_9280)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_4222_9280"
                    x1="2.75"
                    y1="4"
                    x2="16.75"
                    y2="4"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#5DC6CC" />
                    <stop offset="0.98" stop-color="#7279EE" />
                  </linearGradient>
                  <clipPath id="clip0_4222_9280">
                    <rect
                      width="24"
                      height="26"
                      fill="white"
                      transform="translate(0.75)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <p>{option.label}</p>
          </button>
        ))}
      </div>
    </div>
  );
};

export default SuggestionOptions;
