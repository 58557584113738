/* eslint-disable */

import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import { useRouter } from "next/router";
import React from "react";
import { createChatBotMessage, createClientMessage } from "react-chatbot-kit";
import { IMessageOptions } from "react-chatbot-kit/build/src/interfaces/IMessages";

interface ChatMessage {
  message: string;
  type: string;
}

interface ActionProviderProps {
  createChatBotMessage: (
    message: string,
    options?: IMessageOptions
  ) => ReturnType<typeof createChatBotMessage>;
  setState: React.Dispatch<React.SetStateAction<{ messages: ChatMessage[] }>>;
  children: React.ReactNode;
  state: { messages: ChatMessage[] };
}

// eslint-disable-next-line @typescript-eslint/no-shadow
const ActionProvider: React.FC<ActionProviderProps> = ({
  createChatBotMessage,
  setState,
  children,
  state,
}) => {
  const router = useRouter();

  // Get the current route
  const currentRoute = router.asPath;

  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  // get session id from local storage
  const sessionId = localStorage.getItem("chat_session_id");

  const sendMessage = async (
    message: string
  ): Promise<{ response: string }> => {
    // Access the current message history from the chatbot state
    const chatHistory: ChatMessage[] = state.messages;

    // make into a list of strings
    const chatHistoryStrings = chatHistory.map((msg) => ({
      content: msg.message,
      role: msg.type,
    }));

    // Send the current message and the entire history to the endpoint
    interface ApiResponse {
      response: string;
    }

    const response = await api.post<ApiResponse>(
      "/send_message_to_robin_therapist_agent",
      {
        message,
        page_route: currentRoute,
        chat_history: chatHistoryStrings,
        session_id: sessionId,
      },
      {
        headers: {
          Authorization: accessToken || "",
        },
      }
    );

    return response.data;
  };

  // Function to send the current message and history to the endpoint
  const handleMessage = async (message: string): Promise<void> => {
    try {
      const loading = createChatBotMessage("Fetching response...", {});
      setState((prev) => ({ ...prev, messages: [...prev.messages, loading] }));

      const botResponse = await sendMessage(message);
      // Create a chatbot message with the response
      const botMessage = createChatBotMessage(botResponse.response);

      setState((prev) => {
        // Remove Loading here
        const newPrevMsg = prev.messages.slice(0, -1);
        return { ...prev, messages: [...newPrevMsg, botMessage] };
      });
    } catch (error) {
      console.error("Error sending chatbot message:", error);
    }
  };

  const handleSuggestions = (options) => {
    const botMessage = createChatBotMessage(
      "Here's a few suggestions for you:",
      {
        widget: "suggestions",
        withAvatar: true,
        ...options,
      }
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleClickedSuggestion = async (message: string): Promise<void> => {
    const clientMessage = createClientMessage(message, {});

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, clientMessage],
    }));

    const loading = createChatBotMessage("Fetching response...", {});
    setState((prev) => ({ ...prev, messages: [...prev.messages, loading] }));

    const botResponse = await sendMessage(message);
    // Create a chatbot message with the response
    const botMessage = createChatBotMessage(botResponse.response);

    // Update the state with the new message
    setState((prev) => {
      // Remove Loading here
      const newPrevMsg = prev.messages.slice(0, -1);
      return { ...prev, messages: [...newPrevMsg, botMessage] };
    });
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child as React.ReactElement, {
          actions: {
            handleMessage,
            handleSuggestions,
            handleClickedSuggestion,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
