import { useUser } from "@core/services/nocd-api";
import Image from "next/image";
import { createChatBotMessage } from "react-chatbot-kit";
import ReactMarkdown from "react-markdown";
import create from "zustand";

import Suggestions from "./widgets/suggestions";

export const useChatBotStore = create<{
  isChatbotOpen: boolean;
  toggleIsChatbotOpen: () => void;
}>((set) => ({
  isChatbotOpen: false,
  toggleIsChatbotOpen: () =>
    set((state) => ({ isChatbotOpen: !state.isChatbotOpen })),
}));

function InitialMessage(): JSX.Element {
  const { data: user } = useUser();

  return (
    <div style={{ margin: "auto", textAlign: "center", maxWidth: "300px" }}>
      <Image src="/images/robin-icon.svg" alt="Robin" width="78" height="77" />

      <p style={{ color: "rgba(255,255,255,0.8)", fontSize: "22px" }}>
        Hello, {user.first_name}. How can I help you today?
      </p>
    </div>
  );
}

function Header(): JSX.Element {
  const toggleChat = useChatBotStore((state) => state.toggleIsChatbotOpen);
  return (
    <div className="react-chatbot-kit-chat-header">
      <p style={{ padding: "0px", margin: "0px" }}>Robin</p>

      <button
        type="button"
        onClick={toggleChat}
        className="react-chatbot-kit-chat-header-close-button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="white"
          viewBox="0 0 16 16"
        >
          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
        </svg>
      </button>
    </div>
  );
}

// Define the interface for the options argument
interface IMessageOptions {
  widget?: string;
  delay?: number;
  withAvatar?: boolean;
  payload?: any;
}

const MyCustomChatMessage = ({ message }) => {
  // return just the text wihtout markdown
  return (
    <div className="react-chatbot-kit-chat-bot-message-container">
      <ReactMarkdown className="react-chatbot-kit-chat-bot-message">
        {message}
      </ReactMarkdown>
    </div>
  );
};

const config = {
  botName: "Robin",
  initialMessages: [
    createChatBotMessage(null, {
      withAvatar: false,
      widget: "initialMessage",
    } as IMessageOptions),
    createChatBotMessage(null, {
      delay: 500,
      widget: "suggestions",
      withAvatar: true,
    } as IMessageOptions),
  ],
  state: {
    sessionId: "",
  },
  customStyles: {},
  customComponents: {
    // Replace the default bot chat message container with your custom component
    botChatMessage: (props) => <MyCustomChatMessage {...props} />,
    // replace the header with custom element
    header: () => <Header />,
  },
  widgets: [
    {
      widgetName: "suggestions",
      widgetFunc: (props) => <Suggestions {...props} />,
      props: {},
      mapStateToProps: [],
    },
    {
      widgetName: "initialMessage",
      widgetFunc: (_props) => <InitialMessage />,
      props: {},
      mapStateToProps: [],
    },
  ],
};

export default config;
