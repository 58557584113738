import { useSession } from "@core/hooks/useSession";
import { AxiosError } from "axios";
import useSWR, { SWRResponse } from "swr";

import api from "./api";
import { APIError, PremiumClinician } from "./types";
import { transformAPIError } from "./utils";

const getUserData = (
  accessToken: string | undefined
): Promise<PremiumClinician> =>
  api
    .get<PremiumClinician>(
      `/v2/clinician/me`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);

export const useUser = (): SWRResponse<PremiumClinician, Error> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const swrProps = useSWR<PremiumClinician, Error>(
    accessToken ? `me` : undefined,
    () => getUserData(accessToken)
  );

  return swrProps;
};

export const refreshGoogleCalendar = (
  clinicianEmail: string,
  accessToken: string | undefined
): Promise<{ success: true }> =>
  api
    .get<{ success: true }>(
      `/v2/clinician/calendar_events/${encodeURIComponent(
        clinicianEmail
      )}/refresh_gcal`,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });

export const updateCurrentClinician = (
  payload: Partial<
    Pick<
      PremiumClinician,
      | "use_new_messaging"
      | "clinician_timezone"
      | "has_seen_homework_builder_overview"
    >
  >,
  accessToken: string | undefined
): Promise<PremiumClinician> =>
  api
    .post<PremiumClinician>(
      `/v2/clinician/me`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<APIError>) => {
      throw new Error(error?.response?.data?.message ?? error.message);
    });
