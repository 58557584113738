import { useSession } from "@core/hooks/useSession";
import { useQuery, UseQueryOptions } from "react-query";

import api from "../../api";
import { transformAPIError } from "../../utils";
import getQueryKeys from "../query-keys";

interface MembersNotAssignedToRecommendedProtocolResponse {
  clinician_email: string;
  missing_count: number | null;
  members: {
    id: number;
    user_id: number;
    completed_appt_count: number;
    most_recent_appt: string;
    current_scheduling_frequency: string;
    clinical_reviewed_scheduling_frequency: string;
    name: string;
    not_compliant_reason: string;
    not_compliant_reason_details: string;
    not_compliant_reason_updated_at: string;
    not_compliant_reason_updated_by: string;
  }[];
}

const useMembersNotAssignedToRecommendedProtocol = (
  clinicianEmail: string,
  options?: UseQueryOptions<
    MembersNotAssignedToRecommendedProtocolResponse,
    Error
  >
) => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).membersNotAssignedToRecommendedProtocol(
      clinicianEmail
    ),
    ({ signal }) =>
      api
        .get<MembersNotAssignedToRecommendedProtocolResponse>(
          "/v2/clinician/members/not_assigned_to_recommended_protocol",
          {
            params: {
              clinician_email: clinicianEmail,
            },
            headers: {
              Authorization: accessToken,
            },
            signal,
          }
        )
        .then(({ data }) => data)
        .catch(transformAPIError),
    {
      enabled: !!clinicianEmail,
      ...(options ?? {}),
    }
  );
};

export default useMembersNotAssignedToRecommendedProtocol;
