import { EhrRoles } from "@core/constants";
import { useUser } from "@core/services/nocd-api";
import { useMemo } from "react";

const resourcesWithActions = {
  Member: ["read", "update", "delete", "*"] as const,
  SessionNote: ["read", "update", "*"] as const,
  DischargeRequest: ["review"] as const,
  // Add new resources and actions here
} as const;

type Resource = keyof typeof resourcesWithActions;
type ActionForResource<R extends Resource> =
  (typeof resourcesWithActions)[R][number];

type RBAC = {
  [key in EhrRoles]?: {
    [R in Resource]?: ActionForResource<R>[];
  };
};

// Note: This is a work in progress and not the single source of truth.
const rbac: RBAC = {
  // BILLING
  [EhrRoles.BILLING]: {
    SessionNote: ["read"],
    Member: ["read", "update"],
  },

  // CARE TEAM
  [EhrRoles.CARE_TEAM_MANAGER]: {
    Member: ["read", "update", "delete"],
  },
  [EhrRoles.INTAKE]: {},

  // MEMBER ADVOCATES
  [EhrRoles.MEMBER_ADVOCATE]: {},
  [EhrRoles.MEMBER_ADVOCATE_AUDITOR]: {},
  [EhrRoles.MEMBER_ADVOCATE_ADMIN]: {},
  [EhrRoles.MEMBER_ADVOCATE_TRANSFER_CHANNEL_CLAIMS]: {},

  // CLINICAL
  [EhrRoles.CLINICIAN]: {
    Member: ["read", "update"],
    SessionNote: ["read", "update"],
  },
  [EhrRoles.ASSESSMENT_ARCHIVE_CHANGE_REQUEST_ADMIN]: {},
  [EhrRoles.CLINICIAN_PRO]: {},
  [EhrRoles.CLINICIAN_SCHEDULE_APPROVER]: {},
  [EhrRoles.CLINICAL_LEADERSHIP]: {
    DischargeRequest: ["review"],
  },
  [EhrRoles.CLINICAL_LEAD_SPECIALTY_CONDITIONS]: {},
  [EhrRoles.CLINICIAN_SUPERVISOR]: {},
  [EhrRoles.DIAGNOSTIC_APPROVER]: {},
  [EhrRoles.FORM_BASELINE_CHANGE_REQUEST_ADMIN]: {},

  // Misc
  [EhrRoles.DEVELOPER]: {
    Member: ["*"],
    SessionNote: ["*"],
    DischargeRequest: ["review"],
  },
  [EhrRoles.AUDITOR]: {},
  [EhrRoles.SCHEDULING]: {},
  [EhrRoles.NETWORK_OPS_ADMIN]: {},
  [EhrRoles.MEMBER_ACCOUNT_DELETER]: {
    Member: ["read", "update", "delete"],
  },
};

// Check if the two lists of roles overlap. This is really just here
// to make role checking code easier to read.
export const hasRole = (
  roles: EhrRoles[],
  acceptedRoles: EhrRoles[]
): boolean => {
  if (!roles || roles.length === 0) {
    return false;
  }

  return (acceptedRoles ?? []).some((acceptedRole) =>
    roles.includes(acceptedRole)
  );
};

// Example usage:
// hasPermission([EhrRoles.BILLING], "Member", "delete") -> false
// hasPermission([EhrRoles.CARE_TEAM_MANAGER], "Member", "delete") -> true
export const hasPermission = <
  R extends Resource,
  A extends ActionForResource<R>
>(
  roles: EhrRoles[],
  resource: R,
  action: A
): boolean => {
  return (roles ?? []).some((role) => {
    const rolePermissions = rbac[role];
    if (!rolePermissions) {
      return false;
    }

    const matches = rolePermissions[resource];
    if (!matches) {
      return false;
    }

    return (
      matches.includes(action) || matches.includes("*" as ActionForResource<R>)
    );
  });
};

export const useHasPermission = <
  R extends Resource,
  A extends ActionForResource<R>
>(
  resource: R,
  action: A
) => {
  const { data, error } = useUser();

  return useMemo(
    () => ({
      isAuthorizing: !data,
      isAuthorized: hasPermission(data?.roles ?? [], resource, action),
      error,
    }),
    [data, error, action, resource]
  );
};
