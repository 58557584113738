import "../styles/custom.css";
import "react-chatbot-kit/build/main.css";
import "../styles/chatbot.css";

import AppShell from "@core/layout/AppShell";
import AppShellV2 from "@core/layout/AppShellV2";
import createEmotionCache from "@core/lib/createEmotionCache";
import theme from "@core/lib/theme";
import { CacheProvider, EmotionCache } from "@emotion/react";
import NotificationAlerts from "@features/messaging/shared/NotificationAlert";
import CssBaseline from "@mui/material/CssBaseline";
import { Theme, ThemeProvider } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { flow } from "lodash/fp";
import LogRocket from "logrocket";
import { NextPage } from "next";
import { AppProps } from "next/app";
import Head from "next/head";
import Image from "next/image";
import { useRouter } from "next/router";
import type { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { DefaultSeo } from "next-seo";
import NProgress from "nprogress";
import NotoLogo from "public/images/noto-logo.svg";
import { useEffect, useRef } from "react";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

LicenseInfo.setLicenseKey(
  "e45bbf1a67aff90d441ed28e59b78705Tz05MDIyOCxFPTE3NDcyMzg1NDYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

NProgress.configure({
  showSpinner: false,
  template: '<div class="bar" role="bar"><dt></dt><dd></dd></div>',
});

const styles = () => ({
  "@global": {
    "#nprogress": {
      pointerEvents: "none",
      "& .bar": {
        position: "fixed",
        background: theme.palette.common.white,
        borderRadius: 1,
        zIndex: theme.zIndex.tooltip,
        top: 0,
        left: 0,
        width: "100%",
        height: 2,
      },
      "& dd, & dt": {
        position: "absolute",
        top: 0,
        height: 2,
        boxShadow: `${theme.palette.common.white} 1px 0 6px 1px`,
        borderRadius: "100%",
        animation: "nprogress-pulse 2s ease-out 0s infinite",
      },
      "& dd": {
        opacity: 0.6,
        width: 20,
        right: 0,
        clip: "rect(-6px,22px,14px,10px)",
      },
      "& dt": {
        opacity: 0.6,
        width: 180,
        right: -80,
        clip: "rect(-6px,90px,14px,-6px)",
      },
    },
    "@keyframes nprogress-pulse": {
      "30%": {
        opacity: 0.6,
      },
      "60%": {
        opacity: 0,
      },
      to: {
        opacity: 0.6,
      },
    },
  },
});

if (
  typeof window !== "undefined" &&
  process.env.NEXT_PUBLIC_LOGROCKET_PROJECT_ID &&
  process.env.NODE_ENV === "production"
) {
  setTimeout(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_PROJECT_ID);
  }, 10000);
}

interface PageProps {
  dehydratedState?: unknown;
  session?: Session;
}

export interface ExtendedAppProps extends AppProps<PageProps> {
  Component: NextPage<PageProps> & {
    title?: string;
    acceptedRoles?: string[];
    backgroundColor?: "gray" | "white";
    hideShell?: boolean;
    hideSidebar?: boolean;
    closedSidebar?: boolean;
  };
  emotionCache?: EmotionCache;
}

type SetTimeoutReturn = ReturnType<typeof setTimeout>;

function MyApp({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: ExtendedAppProps): JSX.Element {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles);
    }
  }, []);

  const router = useRouter();

  useEffect(() => {
    const delayInMilliseconds = 500;

    let timer: SetTimeoutReturn;

    const startProgressBar = () => {
      timer = setTimeout(() => {
        NProgress.start();
      }, delayInMilliseconds);
    };

    const finishProgressBar = () => {
      clearTimeout(timer);
      NProgress.done();
    };

    router.events.on("routeChangeStart", startProgressBar);
    router.events.on("routeChangeComplete", finishProgressBar);
    router.events.on("routeChangeError", finishProgressBar);

    return () => {
      router.events.off("routeChangeStart", startProgressBar);
      router.events.off("routeChangeComplete", finishProgressBar);
      router.events.off("routeChangeError", finishProgressBar);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    acceptedRoles,
    backgroundColor = "gray",
    hideShell = false,
    hideSidebar = false,
    closedSidebar = false,
  } = Component;

  const queryClientRef = useRef<QueryClient>();

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient();
  }

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicons/apple-touch-icon.png"
        />
        <link
          key="favicon"
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicons/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicons/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicons/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/favicons/safari-pinned-tab.svg"
          color="#00a3ad"
        />
        <meta name="msapplication-TileColor" content="#00a3ad" />
        <meta name="theme-color" content="#ffffff" />

        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <meta name="google" content="notranslate" />
      </Head>

      <DefaultSeo
        title="NOTO"
        titleTemplate="%s / NOCD"
        robotsProps={{
          notranslate: true,
        }}
        dangerouslySetAllPagesToNoFollow
        dangerouslySetAllPagesToNoIndex
      />

      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SessionProvider session={pageProps.session}>
          <QueryClientProvider client={queryClientRef.current}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Toaster />
              {hideSidebar ? (
                <AppShell
                  hideShell={hideShell}
                  title={
                    <Image
                      src={NotoLogo as string}
                      alt="NOTO Logo"
                      width={80}
                      height={80}
                    />
                  }
                  acceptedRoles={acceptedRoles}
                  backgroundColor={backgroundColor}
                >
                  <Component {...pageProps} />
                </AppShell>
              ) : (
                <AppShellV2
                  hideShell={hideShell}
                  hideSidebar={hideSidebar}
                  closedSidebar={closedSidebar}
                  title={
                    <Image
                      src={NotoLogo as string}
                      alt="NOTO Logo"
                      width={80}
                      height={80}
                    />
                  }
                  acceptedRoles={acceptedRoles}
                  backgroundColor={backgroundColor}
                >
                  <Component {...pageProps} />
                </AppShellV2>
              )}

              <NotificationAlerts />
            </ThemeProvider>
          </QueryClientProvider>
        </SessionProvider>
      </LocalizationProvider>
    </CacheProvider>
  );
}

export default flow(
  withStyles(styles),
  withLDProvider({
    clientSideID: process.env.NEXT_PUBLIC_LD_CLIENT_SIDE_ID,
    user: {
      anonymous: true,
    },
    options: {
      bootstrap: "localStorage",
    },
  })
)(MyApp);
