import { useUser } from "@core/services/nocd-api";
import { ChatOutlined, Close, Info } from "@mui/icons-material";
import { Badge, Box, IconButton, Typography } from "@mui/material";
import { sumBy } from "lodash";
import Link from "next/link";
import { useRouter } from "next/router";

import { useWebNotifications } from "../hooks/useWebNotifications";
import { useDismissWebNotification } from "../mutations/useDismissWebNotification";

function NotificationAlerts(): JSX.Element {
  const { data: user } = useUser();
  const disableWebNotificationsFlag = Boolean(
    (user?.flags || []).find((x) => x.flag_name === "disable_web_notifications")
  );

  const router = useRouter();
  const protectedRoutes = ["/messaging", "/appointments/[appointmentId]"];

  const { data: newNotifications } = useWebNotifications({
    disableWebNotificationsFlag,
  });
  const dismissNotification = useDismissWebNotification();

  if (
    !newNotifications ||
    disableWebNotificationsFlag ||
    protectedRoutes.includes(router.pathname)
  ) {
    return null;
  }

  const shouldShowBox =
    Boolean(newNotifications.web_alert_new_message.length) ||
    Boolean(newNotifications.web_alert_24_hours_waiting.length) ||
    Boolean(newNotifications.web_alert_48_hours_waiting.length) ||
    Boolean(newNotifications.web_alert_month_no_contact?.badge_count > 0);

  return (
    <>
      {shouldShowBox && (
        <Box
          display="flex"
          flexDirection="column"
          position="fixed"
          alignItems="flex-start"
          gap={1}
          bottom={30}
          left={40}
          zIndex={10000}
        >
          {Boolean(newNotifications.web_alert_new_message.length) && (
            <Box
              p={3}
              display="flex"
              alignItems="flex-start"
              gap={3}
              style={{ backgroundColor: "#E5F6FD" }}
              borderRadius={2}
              boxShadow={2}
            >
              <Badge
                badgeContent={sumBy(
                  newNotifications.web_alert_new_message,
                  (x) => x.unreads_in_channel
                )}
                max={9}
                color="primary"
              >
                <ChatOutlined color="primary" />
              </Badge>

              <Box display="flex" flexDirection="column" mr={3}>
                <Typography variant="body1" fontWeight="bold">
                  Unread message
                </Typography>

                <Typography variant="body2">
                  You have a new message from a member.{" "}
                  <Link href="/messaging" target="_blank">
                    Let&apos;s help them out!
                  </Link>
                </Typography>
              </Box>

              <IconButton
                edge="end"
                sx={{ p: 0 }}
                onClick={async () => {
                  await dismissNotification.mutateAsync({
                    alertType: "web_alert_new_message",
                  });
                }}
              >
                <Close fontSize="small" />
              </IconButton>
            </Box>
          )}

          {Boolean(newNotifications.web_alert_24_hours_waiting.length) && (
            <Box
              p={3}
              display="flex"
              alignItems="flex-start"
              gap={3}
              style={{ backgroundColor: "rgb(250, 144, 20)" }}
              borderRadius={2}
              boxShadow={2}
            >
              <Badge
                badgeContent={
                  newNotifications.web_alert_24_hours_waiting.length
                }
                max={9}
                color="warning"
              >
                <Typography
                  variant="button"
                  fontWeight="bold"
                  border="2px solid black"
                  borderRadius={8}
                  px={0.5}
                >
                  24
                </Typography>
              </Badge>

              <Box display="flex" flexDirection="column" mr={3}>
                <Typography variant="body1" fontWeight="bold">
                  You still have a message that needs a response
                </Typography>

                <Typography variant="body2">
                  It&apos;s been at least 24 hours since you received messages
                  needing a response.{" "}
                  <Link href="/messaging" target="_blank">
                    Please respond soon
                  </Link>
                </Typography>
              </Box>

              <IconButton
                edge="end"
                sx={{ p: 0 }}
                onClick={async () => {
                  await dismissNotification.mutateAsync({
                    alertType: "web_alert_24_hours_waiting",
                  });
                }}
              >
                <Close fontSize="small" />
              </IconButton>
            </Box>
          )}

          {Boolean(
            newNotifications.web_alert_month_no_contact?.badge_count > 0
          ) && (
            <Box
              p={3}
              display="flex"
              alignItems="flex-start"
              gap={3}
              style={{ backgroundColor: "rgb(235, 225, 100)" }}
              borderRadius={2}
              boxShadow={2}
            >
              <Info />

              <Box display="flex" flexDirection="column" mr={3}>
                <Typography variant="body1" fontWeight="bold">
                  It&apos;s been a month since you messaged{" "}
                  {newNotifications.web_alert_month_no_contact?.badge_count} of
                  you Members.
                </Typography>

                <Typography variant="body2">
                  <Link href="/messaging" target="_blank">
                    Let&apos;s take a moment to see how they&apos;re doing.
                  </Link>
                </Typography>
              </Box>

              <IconButton
                edge="end"
                sx={{ p: 0 }}
                onClick={async () => {
                  await dismissNotification.mutateAsync({
                    alertType: "web_alert_month_no_contact",
                  });
                }}
              >
                <Close fontSize="small" />
              </IconButton>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}

export default NotificationAlerts;
