import SuggestionOptions from "./suggestionOptions";

const Suggestions = (props) => {
  const options = [
    {
      label: "When is my next session",
      value: "When is my next session",
      /* eslint-disable */
      handler: props.actionProvider.handleClickedSuggestion,
      id: 1,
    },
    {
      label: "Summarize what happened last session",
      value: "Summarize what happened last session",
      /* eslint-disable */
      handler: props.actionProvider.handleClickedSuggestion,
    },
    {
      label: "What were their DIAMOND results",
      value: "What were their DIAMOND results",
      /* eslint-disable */
      handler: props.actionProvider.handleClickedSuggestion,
    },
  ];
  return <SuggestionOptions options={options} title="Options" {...props} />;
};

export default Suggestions;
