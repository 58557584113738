import { useSession } from "@core/hooks/useSession";
import { api, transformAPIError } from "@core/services/nocd-api";
import { useQuery, UseQueryResult } from "react-query";

interface UnreadsInChannel {
  unreads_in_channel: number;
  chat_channel_id: number;
}

interface WebAlertMonthNoContact {
  clinician_id: number;
  badge_count: number;
}

export interface WebNotifications {
  web_alert_new_message: UnreadsInChannel[];
  web_alert_24_hours_waiting: UnreadsInChannel[];
  web_alert_48_hours_waiting: UnreadsInChannel[];
  web_alert_month_no_contact: WebAlertMonthNoContact;
}

export const useWebNotifications = ({
  disableWebNotificationsFlag,
}: {
  disableWebNotificationsFlag: boolean;
}): UseQueryResult<WebNotifications, Error> => {
  const { data: session } = useSession();
  const accessToken = session?.accessToken;

  return useQuery(
    `web-notifications-${accessToken}`,
    async ({ signal }) => {
      return api
        .get<WebNotifications>(`/v3/clinician/chat/web_notifications`, {
          headers: {
            authorization: accessToken,
          },
          signal,
        })
        .then((res) => {
          const { data } = res;
          return data;
        })
        .catch(transformAPIError);
    },
    {
      enabled: !!accessToken && !disableWebNotificationsFlag,
      refetchInterval: 60 * 1000, // 60 seconds
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
    }
  );
};
