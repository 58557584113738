import Chatbot from "react-chatbot-kit";
import { v4 as uuidv4 } from "uuid";

import ActionProvider from "./ActionProvider";
import config, { useChatBotStore } from "./config";
import MessageParser from "./MessageParser";
import styles from "./styles.module.css";

// 24 hours in milliseconds
const EXPIRATION_TIME_MS = 3 * 60 * 60 * 1000;
// TherapistChatBot Component
const TherapistChatBot = () => {
  // Get the session ID from localStorage
  let sessionId = localStorage.getItem("chat_session_id");

  // If no session ID exists, create a new one
  if (!sessionId) {
    const uuid = uuidv4(); // Generate a new UUID
    const timestamp = new Date().getTime(); // Get current timestamp
    sessionId = `${uuid}__${timestamp}`; // Create the session ID string
    localStorage.setItem("chat_session_id", sessionId); // Store it in localStorage
  } else {
    // Extract the timestamp from the session ID
    const [, sessionTimestamp] = sessionId.split("__");

    // Convert the sessionTimestamp to a number
    const sessionTimeNumber = Number(sessionTimestamp); // or parseInt(sessionTimestamp, 10);

    // Get the current timestamp
    const currentTime = new Date().getTime();

    // Check if more than 30 seconds have passed
    if (currentTime - sessionTimeNumber > EXPIRATION_TIME_MS) {
      // Remove the chat messages from localStorage
      localStorage.removeItem("chat_messages");

      // Generate a new UUID
      const uuid = uuidv4(); // Generate a new UUID
      const timestamp = new Date().getTime(); // Get current timestamp
      sessionId = `${uuid}__${timestamp}`; // Create the session ID string
      localStorage.setItem("chat_session_id", sessionId); // Store it in localStorage
    }
  }

  const saveMessages = (messages) => {
    localStorage.setItem("chat_messages", JSON.stringify(messages));
  };

  const loadMessages = () => {
    const messages = JSON.parse(localStorage.getItem("chat_messages"));
    return messages;
  };

  return (
    <Chatbot
      config={config}
      headerText="Robin"
      messageParser={MessageParser}
      actionProvider={ActionProvider}
      messageHistory={loadMessages()}
      saveMessages={saveMessages}
    />
  );
};

// TherapistChatToggle Component for toggling chat visibility
const TherapistChatToggle = () => {
  const showChat = useChatBotStore((state) => state.isChatbotOpen);
  const toggleChat = useChatBotStore((state) => state.toggleIsChatbotOpen);

  return (
    <div>
      <button
        style={{
          background: "#242424",
          border: "none",
          margin: "0 10px 0 0",
          borderRadius: "999px",
          padding: "6px 18px",
          color: "white",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "4px",
        }}
        type="button"
        onClick={toggleChat}
      >
        <span>Robin </span>

        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            rotate: showChat ? "180deg" : "none",
            paddingTop: "4px",
          }}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 8.5L9 4L4.5 8.5"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </button>

      {/* Conditionally render the chatbot */}
      {showChat && (
        <div className={styles.chatbotContainer}>
          <TherapistChatBot />
        </div>
      )}
    </div>
  );
};

export default TherapistChatToggle;
