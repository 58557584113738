import { isEmpty } from "lodash";

import type { ClinicianFilters } from "./types";

const getQueryKeys = (accessToken: string | undefined) => ({
  allClinicians: (filters?: ClinicianFilters) => {
    const queryKeyFilters: string[] = [];

    if (filters?.hasUnderscheduledMembers) {
      queryKeyFilters.push("has-underscheduled-members");
    }

    if (filters?.includeRetired) {
      queryKeyFilters.push("include-retired");
    }

    return [
      "clinicians",
      accessToken || "no-access-token",
      isEmpty(queryKeyFilters) ? "no-filters" : queryKeyFilters.join(":"),
    ];
  },
  cliniciansWithUnderscheduledMembers: () => [
    ...getQueryKeys(accessToken).allClinicians(),
    "with-underscheduled-members-only",
  ],
  clinician: (clinicianEmail: string | undefined) => [
    ...getQueryKeys(accessToken).allClinicians(),
    `${clinicianEmail || "no-email"}`,
  ],
  membersNotAssignedToRecommendedProtocol: (
    clinicianEmail: string | undefined
  ) => [
    ...getQueryKeys(accessToken).clinician(clinicianEmail),
    "members-not-assigned-to-recommended-protocol",
  ],
  membersPlanOfSuccession: (clinicianEmail: string | undefined) => [
    ...getQueryKeys(accessToken).clinician(clinicianEmail),
    "members-plan-of-succession",
  ],
  clinicianNotes: (clinicianEmail: string | undefined) => [
    ...getQueryKeys(accessToken).clinician(clinicianEmail),
    "clinician-notes",
  ],
  clinicianTimeline: (clinicianEmail: string | undefined) => [
    ...getQueryKeys(accessToken).clinician(clinicianEmail),
    "clinician-timeline",
  ],
  mostRecentClinicianNote: (clinicianEmail: string | undefined) => [
    ...getQueryKeys(accessToken).clinicianNotes(clinicianEmail),
    "most-recent-clinician-note",
  ],
  clinicianOverrides: (clinicianEmail: string | undefined) => [
    ...getQueryKeys(accessToken).clinician(clinicianEmail),
    "overrides",
  ],
  clinicianFlags: (clinicianEmail: string | undefined) => [
    ...getQueryKeys(accessToken).clinician(clinicianEmail),
    "clinician-flags",
  ],
  allCliniciansSchedulingOverview: () => [
    ...getQueryKeys(accessToken).allClinicians(),
    "scheduling-overview",
  ],
  allCliniciansSchedulingOverviewV2: () => [
    ...getQueryKeys(accessToken).allClinicians(),
    "scheduling-overview-v2",
  ],
  allClinicianAvailabilityRequests: () => [
    ...getQueryKeys(accessToken).allClinicians(),
    "availability-requests",
  ],
  clinicianAvailabilityRequests: (clinicianEmail?: string | undefined) => [
    ...getQueryKeys(accessToken).allClinicianAvailabilityRequests(),
    `${clinicianEmail || "no-email"}`,
  ],
  clinicianAvailabilityRequest: (id: number) => [
    ...getQueryKeys(accessToken).allClinicianAvailabilityRequests(),
    `${id}`,
  ],
});

export default getQueryKeys;
